import { useState } from "react";
import { ReactNotifications, Store } from 'react-notifications-component';
import { CallsHelper } from "../../data/utils/Calls";

const initialFormValues: { [key: string]: string } = {
  first_name: "",
  last_name: "",
  email: "",
  message: "",
};

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as any);
  const [notificationID, setNotificationID] = useState("")

  const validate: any = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name ? "" : "This field is required.";

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";

    if ("message" in fieldValues)
      temp.message =
        fieldValues.message.length !== 0 ? "" : "This field is required.";

    setErrors({
      ...temp
    });
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    validate({ [name]: value });
  };

  const handleSuccess = () => {
    setValues({
      ...initialFormValues
    });
  };

  const handleError = () => {
    setValues({
      ...initialFormValues
    });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.first_name &&
      fieldValues.email &&
      fieldValues.message &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const isValid =
      Object.values(errors).every((x) => x === "") && formIsValid();
    if (isValid) {
      const callsHelper = new CallsHelper();
      callsHelper.postItemNoAuth(`/contact`, values)
        .then(() => {
          // Success case
          console.log("Form submitted successfully");
          handleSuccess();
  
          // Clear the form values
          console.log("Clearing form values to initialFormValues:", initialFormValues);
          setValues({
            ...initialFormValues
          });
  
          setNotificationID(
            Store.addNotification({
              title: "Message sent!",
              message: "Your message has been sent. We will get back to you.",
              type: "success",
              insert: "top",
              container: "top-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            })
          );
        })
        .catch(() => {
          // Error case
          console.log("Form submission failed");
          handleError();
          setNotificationID(
            Store.addNotification({
              title: "Oops!",
              message: "We had a problem submitting your message. Our team will look at this.",
              type: "danger",
              insert: "top",
              container: "top-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            })
          );
        });
    }
  };

  return {
    values,
    errors,
    handleInputValue,
    handleFormSubmit,
    formIsValid
  };
};

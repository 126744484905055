export const hexagonDataMobile = [
  { id: 1, client: false, image: 'https://picsum.photos/128?random=16' },
  { id: 48, client: true, image: '/clients/Zest Fruit logo.png', modal_image: '/clients/Zest Fruit logo.png', name: 'Zest Fruit', description: '' },
  { id: 12, client: false, image: 'https://picsum.photos/128?random=16' },
  { id: 18, client: true, image: '/clients/comdi.jpeg', modal_image: '/clients/comdi.jpeg', name: 'Comdi', description: '' },
  { id: 13, client: false, image: 'https://picsum.photos/128?random=16' },
  { id: 35, client: true, image: '/clients/Dolphin bay-Logo.png', modal_image: '/clients/Dolphin bay-Logo.png', name: 'Dolphin Bay', description: '' },
  { id: 36, client: true, image: '/clients/Dutoit_logo.png', modal_image: '/clients/Dutoit_logo.png', name: 'Dutoit', description: '' },
  { id: 37, client: true, image: '/clients/Komati Fruit Logo.png', modal_image: '/clients/Komati Fruit Logo.png', name: 'Komati Fruit', description: '' },
  { id: 38, client: true, image: '/clients/KWD Feeds logo new.png', modal_image: '/clients/KWD Feeds logo new.png', name: 'KWD Feeds', description: '' },
  { id: 39, client: true, image: '/clients/Mahela Logo.png', modal_image: '/clients/Mahela Logo.png', name: 'Mahela', description: '' },
  { id: 40, client: true, image: '/clients/Meridian Logo.png', modal_image: '/clients/Meridian Logo.png', name: 'Meridian', description: '' },
  { id: 41, client: true, image: '/clients/Noordgrens-Landgoed-Logo-FC-2015.bmp', modal_image: '/clients/Noordgrens-Landgoed-Logo-FC-2015.bmp', name: 'Noordgrens Landgoed', description: '' },
  { id: 42, client: true, image: '/clients/Prospero logo.png', modal_image: '/clients/Prospero logo.png', name: 'Prospero', description: '' },
  { id: 43, client: true, image: '/clients/Sunfare.png', modal_image: '/clients/Sunfare.png', name: 'Sunfare', description: '' },
  { id: 44, client: true, image: '/clients/Umbhaba Logo.png', modal_image: '/clients/Umbhaba Logo.png', name: 'Umbhaba', description: '' },
  { id: 45, client: true, image: '/clients/Verdant orchards logo.png', modal_image: '/clients/Verdant orchards logo.png', name: 'Verdant Orchards', description: '' },
  { id: 46, client: true, image: '/clients/viking-logo.png', modal_image: '/clients/viking-logo.png', name: 'Viking', description: '' },
  { id: 47, client: true, image: '/clients/western cape blood servcies logo.png', modal_image: '/clients/western cape blood servcies logo.png', name: 'Wester Cape Blood Services', description: '' },
  { id: 16, client: false, image: 'https://picsum.photos/128?random=16' },
  { id: 16, client: false, image: 'https://picsum.photos/128?random=16' },
];

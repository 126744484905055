// components/Home.js
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Card, CardContent, Typography, Button, useTheme, useMediaQuery, Modal, Box, IconButton } from '@mui/material';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import FactoryIcon from '@mui/icons-material/Factory';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Hexagon, Honeycomb, ResponsiveHoneycomb } from 'react-honeycomb';
import range from "lodash/range";
import Flag from 'react-world-flags'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VerifiedIcon from '@mui/icons-material/Verified';
import GroupsIcon from '@mui/icons-material/Groups';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';


import logo from '../../assets/images/speconPhone.png';
import openbravo from '../../assets/images/openbravo.png';
import etendo from '../../assets/images/Etendo.png';
import qlik from '../../assets/images/Qlik.png';
import inphinity from '../../assets/images/Inphinity_logo.png';

import { useNavigate } from 'react-router-dom';
import { hexagonData } from './clients';
import { hexagonDataMobile } from './clientsmobile';

export function Home() {


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));


  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact-us'); // Navigate to '/new-route'
  };


  // useEffect(() => {
  //   const getData = async () => {

  //     setNotificationID(Store.addNotification({
  //       title: "Wonderful!",
  //       message: "teodosii@react-notifications-component",
  //       type: "warning",
  //       insert: "top",
  //       container: "top-left",
  //       animationIn: ["animate__animated", "animate__fadeIn"],
  //       animationOut: ["animate__animated", "animate__fadeOut"],
  //       dismiss: {
  //         duration: 2000,
  //         onScreen: true
  //       }
  //     }));

  //     Store.removeNotification(notificationID)
  //     await delay(1000);
  //     setIsLoaded(true)

  //   }
  //   getData()
  // }, [])

  const items = range(304);
  const sideLength = 64;

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ name: '', description: '', image: '' });

  const handleOpen = (item) => {
    if (item.client) {
      setModalContent({ name: item.name, description: item.description, image: item.modal_image });
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={0} style={{ height: "100%", width: "100%" }}>
        {/* Header */}
        <Grid
          item
          xs={12}
          lg={12}
          style={{
            background: "linear-gradient(180deg, #B0CF11 0%, #FBBC05 106.02%)",
            minHeight: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "50%", textAlign: "center" }}>
            <h3 style={{ fontWeight: "bold", fontSize: "5vh", }}>Specialised Solutions to help manage your business.</h3>
            <p style={{ fontSize: "2.5vh", fontWeight: 400, color: "#454545", paddingTop: "2vh", paddingBottom: "2vh" }}>Run your business with confidence</p>
            <Button
              style={{
                background: "#D9D9D9",
                color: "#000115",
                marginBottom: "2vh",
                padding: "14px 24px",
                borderRadius: "8px",
                textTransform: "none"
              }}
              onClick={handleClick}
            >
              Get in touch
            </Button>
          </div>
        </Grid>

        {/* Partners */}
        <Grid item xs={12} lg={12} container spacing={0} style={{ height: "100%", paddingBottom: "7vh", paddingTop: "7vh" }}>
          <Grid item xs={12} lg={6} container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
            <div style={{ width: "70%" }}>
              <Typography style={{
                fontSize: "4vh",
                fontWeight: 400,
                color: "#000115",
                paddingTop: "2vh",
                paddingBottom: "2vh",
                maxWidth: "90%",
                margin: "0 auto",
                textAlign: isSmallScreen ? "center" : "left"
              }}>
                Our team partners with companies specializing in
                <span style={{
                  fontFamily: 'Inter',
                  fontSize: "4vh",
                  fontWeight: 600,
                  textAlign: 'left',
                  marginRight: '0.5rem',
                  marginLeft: '0.5rem' 
                }}>
                  customized software
                </span>
                to ensure we offer the
                <span style={{
                  fontFamily: 'Inter',
                  fontSize: "4vh",
                  fontWeight: 600,
                  textAlign: 'left',
                  marginRight: '0.5rem',
                  marginLeft: '0.5rem' 
                }}>
                  best services tailored to your company's needs.
                </span>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} lg={6} style={{ height: "100%", flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ width: isSmallScreen ? '100%' : "60%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img
                src={openbravo}
                alt="Logo"
                style={{
                  height: '65px',
                  marginBottom: '16px',
                  alignSelf: isSmallScreen ? 'center' : 'flex-end'
                }}
              />
              <img
                src={etendo}
                alt="Logo"
                style={{
                  height: '70px',
                  marginBottom: '16px',
                  alignSelf: isSmallScreen ? 'center' : 'flex-start'
                }}
              />
              <img
                src={qlik}
                alt="Logo"
                style={{
                  height: '100px',
                  marginBottom: isSmallScreen ? '16px' : '-5vh',
                  alignSelf: isSmallScreen ? 'center' : 'flex-end'
                }}
              />
              <img
                src={inphinity}
                alt="Logo"
                style={{
                  height: '105px',
                  marginBottom: '16px',
                  alignSelf: isSmallScreen ? 'center' : 'flex-start'
                }}
              />
            </div>
          </Grid>
        </Grid>

        {/* Experience */}
        <Grid item xs={12} lg={12} style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingBottom: "7vh" }}>
          <Card style={{
            backgroundColor: '#FFFFFF',
            borderRadius: '56px',
            padding: '16px',
            width: '90%',
            margin: 'auto',
            height: isSmallScreen ? '100%' : "37vh",
            boxShadow: `
            -4vh 2vh 20vh rgba(0, 0, 0, 0.2),
            -12vh 8vh 30vh rgba(0, 0, 0, 0.2)
          `
          }}>

            <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography style={{
                color: '#000115',
                fontFamily: 'Inter',
                fontSize: isSmallScreen ? '2vh' : '4vh',
                fontWeight: 600,
                letterSpacing: '-0.02em',
                textAlign: 'center',
                width: "70%"
              }}>
                We leverage an excess of <span style={{
                  fontFamily: 'Inter',
                  fontSize: isSmallScreen ? '2.2vh' : '4.2vh',
                  fontWeight: 800,
                  letterSpacing: '-0.02em',
                  textAlign: 'center'
                }}>50 years experience in ERP and BI implementations</span> from small to medium-sized businesses.
              </Typography>
            </CardContent>

          </Card>
        </Grid>

        {/* Industries */}
        <Grid item xs={12} lg={12} style={{ paddingBottom: '7vh' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "4vh",
              fontWeight: 500,
              letterSpacing: "-0.02em",
            }}>
              Adapted for various Industries
            </Typography>
          </div>
        </Grid>

        {/* Icon grid */}
        <Grid item xs={12} lg={12} container justifyContent="center" spacing={0} style={{ paddingBottom: '7vh' }}>
          <Grid item xs={12} style={{ textAlign: 'center', width: "60%" }}>
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={6} lg={2.4} style={{ textAlign: 'center' }}>
                <AgricultureIcon style={{ fontSize: "11vh" }} />
                <Typography style={{
                  fontFamily: 'Inter',
                  fontSize: '2vh',
                  fontWeight: 400,
                  letterSpacing: '-0.02em',
                  textAlign: 'center'
                }}>
                  Agriculture
                </Typography>
              </Grid>
              <Grid item xs={6} lg={2.4} style={{ textAlign: 'center' }}>
                <FactoryIcon style={{ fontSize: "11vh" }} />
                <Typography style={{
                  fontFamily: 'Inter',
                  fontSize: '2vh',
                  fontWeight: 400,
                  letterSpacing: '-0.02em',
                  textAlign: 'center'
                }}>
                  Industrial
                </Typography>
              </Grid>
              <Grid item xs={6} lg={2.4} style={{ textAlign: 'center' }}>
                <svg width="11vh" height="11vh" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 11.5v-2h9v2h-9Z" fill="#000000" />
                  <path d="M4 3a2 2 0 0 0-1 3.732V20.25c0 .414.336.75.75.75H6v-5.25a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 .75.75V21h8.75a.75.75 0 0 0 .75-.75V6.732A2 2 0 0 0 20 3H4Zm-.5 2a.5.5 0 0 1 .5-.5h16a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5Zm3.25 3h10.5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75v-3.5A.75.75 0 0 1 6.75 8Zm8 7h2.5a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-.75.75h-2.5a.75.75 0 0 1-.75-.75v-2.5a.75.75 0 0 1 .75-.75Z" fill="#000000" />
                  <path d="M10 16.5V21H7.5v-4.5H10Z" fill="#000000" />
                </svg>
                <Typography style={{
                  fontFamily: 'Inter',
                  fontSize: '2vh',
                  fontWeight: 400,
                  letterSpacing: '-0.02em',
                  textAlign: 'center'
                }}>
                  Retail
                </Typography>
              </Grid>
              <Grid item xs={6} lg={2.4} style={{ textAlign: 'center' }}>
                <AccountBalanceIcon style={{ fontSize: "11vh" }} />
                <Typography style={{
                  fontFamily: 'Inter',
                  fontSize: '2vh',
                  fontWeight: 400,
                  letterSpacing: '-0.02em',
                  textAlign: 'center'
                }}>
                  Finance
                </Typography>
              </Grid>
              <Grid item xs={6} lg={2.4} style={{ textAlign: 'center' }}>
                <LocalShippingIcon style={{ fontSize: "11vh" }} />
                <Typography style={{
                  fontFamily: 'Inter',
                  fontSize: '2vh',
                  fontWeight: 400,
                  letterSpacing: '-0.02em',
                  textAlign: 'center'
                }}>
                  Distribution
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12} style={{ paddingBottom: '7vh', display: 'flex', justifyContent: 'center' }}>
          <div style={{ textAlign: 'center', width: "70%" }}>
            <Typography style={{
              fontFamily: 'Inter',
              fontSize: '3vh',
              fontWeight: 400,
              letterSpacing: '-0.02em',
              textAlign: 'center'
            }}>
              Our services are not limited to specific industries or types of companies. We are able to assist a wide range of businesses and their respective obstacles.
            </Typography>
          </div>
        </Grid>



        {/* Clients */}
        <Grid item xs={12} lg={12} style={{ paddingBottom: '7vh' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "4vh",
              fontWeight: 500,
              letterSpacing: "-0.02em",
            }}>
              Our Clients
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={12} style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingBottom: "7vh" }}>

          <Card style={{
            backgroundColor: '#FFFFFF',
            borderRadius: '56px',
            padding: '16px',
            width: '90%',
            margin: 'auto',
            height: "37vh",
            boxShadow: `
            -4vh 2vh 20vh rgba(0, 0, 0, 0.2),
            -12vh 8vh 30vh rgba(0, 0, 0, 0.2)
          `
          }}>

            <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {
                isSmallScreen ? (<>
                  <Honeycomb
                    columns={5}
                    size={64}
                    items={hexagonDataMobile}
                    renderItem={(item, index) => (
                      <Hexagon key={item.id}>
                        <img
                          src={item.image}
                          alt={`Hexagon #${item.id}`}
                          className={` ${!item.client ? 'grey-image' : 'hover-image'}`}
                          onClick={() => item.client && handleOpen(item)}
                        />
                      </Hexagon>
                    )}
                  />
                  <Modal open={open} onClose={handleClose}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100vh"
                      onClick={handleClose} // Close the modal when clicking outside
                    >
                      <Box
                        onClick={(e) => e.stopPropagation()} // Prevent closing the modal when clicking inside
                        style={{
                          position: 'relative',
                          padding: 20,
                          backgroundColor: 'white',
                          borderRadius: 8,
                          width: '70%',
                          maxWidth: '70%',
                          textAlign: 'center',
                          outline: 'none' // Remove default outline
                        }}
                      >
                        <IconButton
                          onClick={handleClose}
                          style={{ position: 'absolute', top: 10, right: 10 }}
                        >
                          <CloseIcon />
                        </IconButton>
                        {/* <h2>{modalContent.name}</h2>
                        <p>{modalContent.description}</p> */}
                        <img
                          src={modalContent.image}
                          alt={`Hexagon #${modalContent.id}`}
                          style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                        />
                      </Box>
                    </Box>
                  </Modal>
                </>) : (<>
                  <Honeycomb
                    columns={14}
                    size={64}
                    items={hexagonData}
                    renderItem={(item, index) => (
                      <Hexagon key={item.id}>
                        <div className="image-container">
                          <img
                            src={item.image}
                            alt={`Hexagon #${item.id}`}
                            className={`${!item.client ? 'grey-image' : 'hover-image'}`}
                            onClick={() => item.client && handleOpen(item)}
                          />
                        </div>
                      </Hexagon>
                    )}
                  />
                  <Modal open={open} onClose={handleClose}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100vh"
                      onClick={handleClose} // Close the modal when clicking outside
                    >
                      <Box
                        onClick={(e) => e.stopPropagation()} // Prevent closing the modal when clicking inside
                        style={{
                          position: 'relative',
                          padding: 20,
                          backgroundColor: 'white',
                          borderRadius: 8,
                          width: '80%',
                          maxWidth: '50vw',
                          textAlign: 'center',
                          outline: 'none' // Remove default outline
                        }}
                      >
                        <IconButton
                          onClick={handleClose}
                          style={{ position: 'absolute', top: 10, right: 10 }}
                        >
                          <CloseIcon />
                        </IconButton>
                        {/* <h2>{modalContent.name}</h2>
                        <p>{modalContent.description}</p> */}
                        <img
                          src={modalContent.image}
                          alt={`Hexagon #${modalContent.id}`}
                          style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                        />
                      </Box>
                    </Box>
                  </Modal>
                </>)
              }

            </CardContent>

          </Card>
        </Grid>
        {/* Reasons */}
        <Grid item xs={12} lg={12} container spacing={0} style={{ height: "100%", paddingBottom: "7vh" }}>
          <Grid item xs={12} lg={5} container justifyContent="center" alignItems="center" style={{ minHeight: "100%" }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                height: '80%',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          </Grid>




          <Grid item xs={12} lg={7} style={{ minHeight: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography style={{
              fontFamily: 'Inter',
              fontSize: '4vh',
              fontWeight: 500,
              letterSpacing: '-0.02em',
              textAlign: 'center',
              marginBottom: '8vh'
            }}>
              4 Reasons to choose us
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ marginBottom: '6vh', width: '100%' }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    alignItems: isSmallScreen ? 'center' : 'center',
                    textAlign: isSmallScreen ? 'center' : 'left',
                    width: '100%'
                  }}>
                    <EmojiEventsIcon style={{ color: "#B0CF11", fontSize: "50px", marginRight: isSmallScreen ? '0' : '8px', marginBottom: isSmallScreen ? '8px' : '0' }} />
                    <Typography style={{
                      fontFamily: 'Inter',
                      fontSize: '2vh',
                      fontWeight: 400,
                      letterSpacing: '-0.02em',
                      textAlign: isSmallScreen ? 'center' : 'left',
                      width: '100%',
                      paddingLeft: isSmallScreen ? '0' : "2vw"
                    }}>
                      We are experts in our field with expansive knowledge and experience of ERP and BI implementations and business solutions
                    </Typography>
                  </div>
                </div>

                <div style={{ marginBottom: '6vh', width: '100%' }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    alignItems: isSmallScreen ? 'center' : 'center',
                    textAlign: isSmallScreen ? 'center' : 'left',
                    width: '100%'
                  }}>
                    <VerifiedIcon style={{ color: "#B0CF11", fontSize: "50px", marginRight: isSmallScreen ? '0' : '8px', marginBottom: isSmallScreen ? '8px' : '0' }} />
                    <Typography style={{
                      fontFamily: 'Inter',
                      fontSize: '2vh',
                      fontWeight: 400,
                      letterSpacing: '-0.02em',
                      textAlign: isSmallScreen ? 'center' : 'left',
                      width: '100%',
                      paddingLeft: isSmallScreen ? '0' : "2vw"
                    }}>
                      We offer flexible solutions tailored to your needs
                    </Typography>
                  </div>
                </div>

                <div style={{ marginBottom: '6vh', width: '100%' }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    alignItems: isSmallScreen ? 'center' : 'center',
                    textAlign: isSmallScreen ? 'center' : 'left',
                    width: '100%'
                  }}>
                    <GroupsIcon style={{ color: "#B0CF11", fontSize: "50px", marginRight: isSmallScreen ? '0' : '8px', marginBottom: isSmallScreen ? '8px' : '0' }} />
                    <Typography style={{
                      fontFamily: 'Inter',
                      fontSize: '2vh',
                      fontWeight: 400,
                      letterSpacing: '-0.02em',
                      textAlign: isSmallScreen ? 'center' : 'left',
                      width: '100%',
                      paddingLeft: isSmallScreen ? '0' : "2vw"
                    }}>
                      Our team has highly qualified specialists
                    </Typography>
                  </div>
                </div>

                <div style={{ marginBottom: '6vh', width: '100%' }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    alignItems: isSmallScreen ? 'center' : 'center',
                    textAlign: isSmallScreen ? 'center' : 'left',
                    width: '100%'
                  }}>
                    <FavoriteIcon style={{ color: "#B0CF11", fontSize: "50px", marginRight: isSmallScreen ? '0' : '8px', marginBottom: isSmallScreen ? '8px' : '0' }} />
                    <Typography style={{
                      fontFamily: 'Inter',
                      fontSize: '2vh',
                      fontWeight: 400,
                      letterSpacing: '-0.02em',
                      textAlign: isSmallScreen ? 'center' : 'left',
                      width: '100%',
                      paddingLeft: isSmallScreen ? '0' : "2vw"
                    }}>
                      We aim to deliver the best experiences and services and value quality.
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        {/* Countries */}
        <Grid item xs={12} lg={12} container spacing={0} style={{ height: "100%", paddingBottom: "7vh" }}>
          <Grid item xs={12} lg={5} style={{ minHeight: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: "70%", textAlign: 'left' }}>
              <Typography sx={{
                fontFamily: 'Inter',
                fontSize: '3vh',
                fontWeight: 400,
                letterSpacing: '-0.02em',
                textAlign: isSmallScreen ? 'center' : 'left'
              }}>
                Our company operates internationally with our main offices based in 
                <span style={{
                  fontFamily: 'Inter',
                  fontSize: '32px',
                  fontWeight: 600,
                  letterSpacing: '-0.02em',
                  textAlign: 'center',
                  marginRight: '0.5rem',
                  marginLeft: '0.5rem' 
                }}>
                  South Africa and Mauritius.
                </span>
              </Typography>
            </div>
          </Grid>
          <Grid container spacing={2} item xs={12} lg={6} justifyContent="center" alignItems="center">
            <Grid item xs={6} lg={6} style={{ textAlign: 'center' }}>
              <LocationOnIcon style={{ fontSize: 61, marginBottom: "2vh" }} />
              <div style={{ color: "#000115", fontSize: "2vh" }}>
                Somerset West, Western Cape, South Africa
              </div>
            </Grid>
            <Grid item xs={6} lg={6} style={{ textAlign: 'center' }}>
              <LocationOnIcon style={{ fontSize: 61, marginBottom: "2vh" }} />
              <div style={{ color: "#000115", fontSize: "2vh" }}>
                Moka, Mauritius
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* End */}
      </Grid >
    </>


  );
}

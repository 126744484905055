export const hexagonData = [
    { id: 1, client: false, image: 'https://picsum.photos/128?random=1' },
    { id: 2, client: false, image: 'https://picsum.photos/128?random=2'},
    { id: 3, client: false, image: 'https://picsum.photos/128?random=3' },
    { id: 4, client: false, image: 'https://picsum.photos/128?random=4' },
    { id: 5, client: false, image: 'https://picsum.photos/128?random=5'},
    { id: 6, client: false, image: 'https://picsum.photos/128?random=6'},
    { id: 7, client: false, image: 'https://picsum.photos/128?random=7' },
    { id: 8, client: false, image: 'https://picsum.photos/128?random=8' },
    { id: 9, client: false, image: 'https://picsum.photos/128?random=9'},
    { id: 10, client: false, image: 'https://picsum.photos/128?random=10'},
    { id: 11, client: false, image: 'https://picsum.photos/128?random=11'},
    { id: 12, client: false, image: 'https://picsum.photos/128?random=12'},
    { id: 13, client: false, image: 'https://picsum.photos/128?random=13' },
    { id: 14, client: false, image: 'https://picsum.photos/128?random=14' },
    { id: 15, client: false, image: 'https://picsum.photos/128?random=15'},
    { id: 48, client: true, image: '/clients/Zest Fruit logo.png', modal_image: '/clients/Zest Fruit logo.png', name: 'Zest Fruit', description: ''},
    { id: 17, client: false, image: 'https://picsum.photos/128?random=17' },
    { id: 18, client: true, image: '/clients/comdi.jpeg', modal_image: 'clients/comdi.jpeg', name: 'Comdi', description: ''},
    { id: 19, client: false, image: 'https://picsum.photos/128?random=18' },
    { id: 20, client: false, image: 'https://picsum.photos/128?random=19'},
    { id: 35, client: true, image: '/clients/Dolphin bay-Logo-long.png', modal_image: '/clients/Dolphin bay-Logo-long.png', name: 'Dolphin Bay', description: ''},
    { id: 21, client: false, image: 'https://picsum.photos/128?random=20'},
    { id: 22, client: false, image: 'https://picsum.photos/128?random=21'},
    { id: 36, client: true, image: '/clients/Dutoit_logo.png', modal_image: '/clients/Dutoit_logo.png', name: 'Dutoit', description: ''},
    { id: 23, client: false, image: 'https://picsum.photos/128?random=22'},
    { id: 24, client: false, image: 'https://picsum.photos/128?random=23' },
    { id: 37, client: true, image: '/clients/Komati Fruit Logo.png', modal_image: '/clients/Komati Fruit Logo.png', name: 'Komati Fruit', description: ''},
    { id: 25, client: false, image: 'https://picsum.photos/128?random=24' },
    { id: 26, client: false, image: 'https://picsum.photos/128?random=25'},
    { id: 38, client: true, image: '/clients/KWD Feeds logo new.png', modal_image: '/clients/KWD Feeds logo new.png', name: 'KWD Feeds', description: ''},
    { id: 27, client: false, image: 'https://picsum.photos/128?random=26'},
    { id: 28, client: false, image: 'https://picsum.photos/128?random=27' },
    { id: 39, client: true, image: '/clients/Mahela Logo.png', modal_image: '/clients/Mahela Logo.png', name: 'Mahela', description: ''},
    { id: 29, client: false, image: 'https://picsum.photos/128?random=28' },
    { id: 30, client: false, image: 'https://picsum.photos/128?random=29'},
    { id: 40, client: true, image: '/clients/Meridian Logo.png', modal_image: '/clients/Meridian Logo.png', name: 'Meridian', description: ''},
    { id: 31, client: false, image: 'https://picsum.photos/128?random=30'},
    { id: 32, client: false, image: 'https://picsum.photos/128?random=31'},
    { id: 41, client: true, image: '/clients/Noordgrens-Landgoed-Logo-FC-2015.bmp', modal_image: '/clients/Noordgrens-Landgoed-Logo-FC-2015.bmp', name: 'Noordgrens Landgoed', description: ''},
    { id: 33, client: false, image: 'https://picsum.photos/128?random=32'},
    { id: 42, client: true, image: '/clients/Prospero logo.png', modal_image: '/clients/Prospero logo.png', name: 'Prospero', description: ''},
    { id: 34, client: false, image: 'https://picsum.photos/128?random=33' },
    { id: 49, client: false, image: 'https://picsum.photos/128?random=50'},
    { id: 43, client: true, image: '/clients/Sunfare.png', modal_image: '/clients/Sunfare.png', name: 'Sunfare', description: ''},
    { id: 50, client: false, image: 'https://picsum.photos/128?random=50'},
    { id: 44, client: true, image: '/clients/Umbhaba Logo.png', modal_image: '/clients/Umbhaba Logo.png', name: 'Umbhaba', description: ''},
    { id: 51, client: false, image: 'https://picsum.photos/128?random=51'},
    { id: 52, client: false, image: 'https://picsum.photos/128?random=52'},
    { id: 45, client: true, image: '/clients/Verdant orchards logo.png', modal_image: '/clients/Verdant orchards logo.png', name: 'Verdant Orchards', description: ''},
    { id: 53, client: false, image: 'https://picsum.photos/128?random=53' },
    { id: 54, client: false, image: 'https://picsum.photos/128?random=54' },
    { id: 46, client: true, image: '/clients/viking-logo.png', modal_image: '/clients/viking-logo.png', name: 'Viking', description: ''},
    { id: 55, client: false, image: 'https://picsum.photos/128?random=55'},
    { id: 56, client: false, image: 'https://picsum.photos/128?random=56'},
    { id: 47, client: true, image: '/clients/western cape blood servcies logo.png', modal_image: '/clients/western cape blood servcies logo.png', name: 'Wester Cape Blood Services', description: ''},
    { id: 16, client: false, image: 'https://picsum.photos/128?random=16'},
    { id: 57, client: false, image: 'https://picsum.photos/128?random=57' },
    { id: 58, client: false, image: 'https://picsum.photos/128?random=58' },
    { id: 59, client: false, image: 'https://picsum.photos/128?random=59'},
    { id: 60, client: false, image: 'https://picsum.photos/128?random=60'},
    { id: 61, client: false, image: 'https://picsum.photos/128?random=61'},
    { id: 62, client: false, image: 'https://picsum.photos/128?random=62'},
    { id: 63, client: false, image: 'https://picsum.photos/128?random=63' },
    { id: 64, client: false, image: 'https://picsum.photos/128?random=64' },
    { id: 65, client: false, image: 'https://picsum.photos/128?random=65'},
    { id: 66, client: false, image: 'https://picsum.photos/128?random=66'},
    { id: 67, client: false, image: 'https://picsum.photos/128?random=67' },
    { id: 68, client: false, image: 'https://picsum.photos/128?random=68' },
    { id: 69, client: false, image: 'https://picsum.photos/128?random=69'},
    { id: 70, client: false, image: 'https://picsum.photos/128?random=70'},
  ];
  
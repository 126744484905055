import "./Footer.css";
import Grid from '@mui/material/Grid';
import logo from '../../assets/images/logo.jpeg';
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Link from '@mui/material/Link';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


export function Footer() {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <footer style={{ color: "gray", width: '100%', height: "20vh", backgroundColor: "#FFFFFF", borderTop: "1px solid #E6E6E6", flexShrink: 0 }}>

            <Grid container spacing={0} style={{}}>
                <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: isSmallScreen ? 'center' : 'flex-start' }}>
                    <img
                        src={logo}
                        alt="Logo"
                        style={{
                            height: '70%',
                            width: isSmallScreen ? '80%' : '60%',
                            marginRight: isSmallScreen ? '0px' : '16px'
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6} container spacing={0} style={{ backgroundColor: "#B0CF11", height: "100%" }}>
                    <Grid item xs={12} md={6} container spacing={0} style={{ backgroundColor: "#B0CF11", height: "100%" }}>
                        <Grid item xs={12} md={6} style={{ backgroundColor: "#B0CF11", height: "100%", display: 'flex', justifyContent: isSmallScreen ? 'center' : 'flex-start', alignItems: 'center' }}>
                            <div style={{ paddingLeft: isSmallScreen ? '0px' : "5vw", display: 'flex', flexDirection: 'column', height: '80%', justifyContent: isSmallScreen ? 'center' : 'space-between', textAlign: isSmallScreen ? 'center' : 'left' }}>
                                <Typography style={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '2.2vh',
                                    fontWeight: 500,
                                    textAlign: isSmallScreen ? 'center' : 'left',
                                    color: '#000115',
                                    paddingBottom: "1vh",
                                    paddingTop: "1vh", 
                                    marginBottom: "1vh"
                                }}>
                                    Contact Info
                                </Typography>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSmallScreen ? 'center' : 'flex-start', paddingBottom: "1vh" }}>
                                    <EmailIcon style={{ verticalAlign: 'middle', color: '#000115' }} />
                                    <Link href="mailto:info@specon.net" color="inherit" style={{ textDecoration: 'none', marginLeft: "0.5vw", fontFamily: 'Inter, sans-serif', fontSize: "1.8vh", fontWeight: 500, color: '#000115' }}>
                                        info@specon.net
                                    </Link>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSmallScreen ? 'center' : 'flex-start', paddingBottom: "1vh" }}>
                                    <LanguageIcon style={{ verticalAlign: 'middle', color: '#000115' }} />
                                    <Link href="https://specon.net/" color="inherit" style={{ textDecoration: 'none', marginLeft: "0.5vw", fontFamily: 'Inter, sans-serif', fontSize: "1.8vh", fontWeight: 500, color: '#000115' }}>
                                        https://specon.net/
                                    </Link>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSmallScreen ? 'center' : 'flex-start', paddingBottom: "1vh" }}>
                                    <LocalPhoneIcon style={{ verticalAlign: 'middle', color: '#000115' }} />
                                    <Link href="tel:+230 5826 3242" color="inherit" style={{ textDecoration: 'none', marginLeft: "0.5vw", fontFamily: 'Inter, sans-serif', fontSize: "1.8vh", fontWeight: 500, color: '#000115' }}>
                                        +230 5826 3242
                                    </Link>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSmallScreen ? 'center' : 'flex-start', paddingBottom: "1vh" }}>
                                    <WhatsAppIcon style={{ verticalAlign: 'middle', color: '#000115' }} />
                                    <Link href="https://wa.me/+27795248902" color="inherit" style={{ textDecoration: 'none', marginLeft: "0.5vw", fontFamily: 'Inter, sans-serif', fontSize: "1.8vh", fontWeight: 500, color: '#000115' }}>
                                        +27 79 524 8902
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    {
                        !isSmallScreen && (<>
                            <Grid item xs={12} md={6} style={{ backgroundColor: "#B0CF11", minHeight: "100%", display: 'flex', justifyContent: isSmallScreen ? 'center' : 'left', alignItems: 'left' }}>
                                <div style={{ paddingLeft: isSmallScreen ? '0px' : "5vw", display: 'flex', flexDirection: 'column', height: '80%', justifyContent: isSmallScreen ? 'center' : 'space-between' }}>
                                    <Typography style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontSize: '2.2vh',
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#000115',
                                        paddingTop: "1vh",
                                        paddingBottom: "1.3vh",
                                        marginBottom: "1vh"
                                    }}>
                                        Links
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontSize: "1.8vh",
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#000115',
                                        paddingBottom: "1.3vh"
                                    }}>
                                        <Link href="/home" color="inherit" style={{ textDecoration: 'none' }}>
                                            Home
                                        </Link>
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontSize: "1.8vh",
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#000115',
                                        paddingBottom: "1.3vh"
                                    }}>
                                        <Link href="/services" color="inherit" style={{ textDecoration: 'none' }}>
                                            Services
                                        </Link>
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: 'Inter, sans-serif',
                                        fontSize: "1.8vh",
                                        fontWeight: 500,
                                        textAlign: 'left',
                                        color: '#000115',
                                        paddingBottom: "1.3vh"
                                    }}>
                                        <Link href="/contact-us" color="inherit" style={{ textDecoration: 'none' }}>
                                            Get in touch
                                        </Link>
                                    </Typography>
                                </div>
                            </Grid>
                        </>)
                    }

                </Grid>
            </Grid>
        </footer >
    );
}

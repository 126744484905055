import React from 'react';

const CurvedRectangle = () => {
  const styles = {
    container: {
      width: '90%',  // Adjust width as needed
      height: '60px',  // Adjust height as needed
      backgroundColor: '#B0CF11',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      borderBottomLeftRadius: '40px',  // Adjust radius as needed
    }
  };

  return (
    <div style={styles.container}></div>
  );
};

export default CurvedRectangle;

import { ContactUs } from "./pages/contact/contactUs";
import { Home } from "./pages/home/home"
import { Services } from "./pages/services/Services";
import { Protected } from './pages/userHome/userHome';

export interface AppRoute{
    Navigation: string,
    DisplayName: string,
    Protected: boolean,
    Component: () => JSX.Element,
    NavBarVisibile: boolean
}

export const CustomRoutes : AppRoute[] = [
    {
        DisplayName: 'Home',
        Navigation: '/home',
        Protected: false,
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Services',
        Navigation: '/services',
        Protected: false,
        Component: Services,
        NavBarVisibile: true
    },
    // {
    //     DisplayName: 'Product Types',
    //     Navigation: '/products/:id',
    //     Protected: false,
    //     Component: Products,
    //     NavBarVisibile: false
    // },
    {
        DisplayName: 'Get in Touch',
        Navigation: '/contact-us',
        Protected: false,
        Component: ContactUs,
        NavBarVisibile: true
    },
   
    
]

export const SettingRoutes : AppRoute[] = [
    // {
    //     DisplayName: 'Profile',
    //     Navigation: '/profile',
    //     Protected: true,
    //     Component: Protected,
    //     NavBarVisibile: true
    // },
]
import React from 'react';
import { Grid, Card, CardContent, Box, Divider, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import PieChartIcon from '@mui/icons-material/PieChart';
import { faMagnifyingGlassChart, faGear } from '@fortawesome/free-solid-svg-icons';
import mindMap from '../../assets/images/mindmap.png';
import chartDiagram from '../../assets/images/Group 2.png';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Services() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = () => {
    navigate('/contact-us');
  };



  return (
    <Grid container spacing={2} style={{ height: "100%", width: "100%", padding: isSmallScreen ? '16px' : '32px' }}>
      <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '3vh' }}>
        <Typography variant="h1" style={{ color: "#000115", fontSize: isSmallScreen ? "3vh" : "5vh" }}>
          Our services are designed to help you
        </Typography>
        <Typography variant="h1" style={{ color: "#FBBC05", fontSize: isSmallScreen ? "3vh" : "5vh" }}>
          manage your business flow.
        </Typography>
        <Typography variant="body1" style={{ marginTop: "3vh" }}>
          We offer a broad spectrum of services and solutions tailored to fit your business model, needs and goals.
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} style={{
        height: isSmallScreen ? "100%" : "50vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
      }}>
        <Typography variant="h2" style={{ fontWeight: 300, fontSize: "4vh", width: isSmallScreen ? "100%" : "80%", textAlign: isSmallScreen ? "center" : "left" }}>
          Our focus is split in <span style={{ fontWeight: 'bold' }}>Two</span>
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 300, fontSize: 24, width: isSmallScreen ? "100%" : "80%", textAlign: isSmallScreen ? "center" : "left" }}>
          We focus on two main areas of business
        </Typography>
      </Grid>

      {
        !isSmallScreen && (<>
          <Grid item xs={12} md={6} container spacing={2} >

            {/* First Card */}
            <Grid
              item
              xs={12}
              md={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: "100%"
              }}
            >
              <Card
                style={{
                  backgroundColor: '#FAE588',
                  borderRadius: '3vw',
                  padding: '16px',
                  textAlign: 'center',
                  height: '30vh',
                  width: isSmallScreen ? '100%' : '90%'
                }}
              >
                <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                    {[...Array(4)].map((_, index) => (
                      <Box key={index} display="flex" alignItems="center" width="100%" mb={1}>
                        <Box width={20} height={20} bgcolor={index % 2 === 0 ? '#000115' : '#FBBC05'} mr={1} />
                        <Divider style={{ borderWidth: '4px', flexGrow: 1 }} />
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Second Card */}
            <Grid item xs={12} md={3} style={{ display: isSmallScreen ? 'block' : 'flex', justifyContent: 'center', alignItems: isSmallScreen ? 'center' : 'flex-start', marginTop: isSmallScreen ? '0' : '5vh' }}>
              <Card style={{ backgroundColor: '#E0F483', borderRadius: '3vw', padding: '16px', textAlign: 'center', height: '30vh', width: isSmallScreen ? '100%' : '90%' }}>

                <CardContent style={{ height: '100%' }}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Box style={{ flex: 1, textAlign: 'left' }}>
                      <PieChartIcon style={{ fontSize: "12vh", paddingRight: "20%", color: '#B0CF11' }} />
                    </Box>
                    <Box>
                      <PieChartIcon style={{ fontSize: "12vh", paddingLeft: "20%", color: '#000115' }} />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Third Card */}
            <Grid item xs={12} md={3} style={{ display: isSmallScreen ? 'block' : 'flex', justifyContent: 'center', alignItems: isSmallScreen ? 'center' : 'flex-end', marginBottom: isSmallScreen ? '0' : '5vh' }}>
              <Card style={{ backgroundColor: '#202453CC', borderRadius: '3vw', padding: '16px', textAlign: 'center', height: '30vh', width: isSmallScreen ? '100%' : '90%' }}>

                <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <img
                    src={chartDiagram}
                    alt="Logo"
                    style={{
                      width: isSmallScreen ? "40%" : "80%",
                      marginBottom: '16px'
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>)
      }

      {/* BI and ERP descriptions */}
      <Grid item xs={12} container spacing={2} style={{ paddingTop: '3vh' }}>
        <Grid item xs={12} md={3} style={{ textAlign: isSmallScreen ? 'center' : 'center' }}>
          <FontAwesomeIcon icon={faGear} style={{ fontSize: "12vh", color: "#202453" }} />
        </Grid>
        <Grid item xs={12} md={9} style={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
          <Box>
            <Typography variant="h3" style={{
              fontFamily: 'Inter',
              fontSize: '3vh',
              fontWeight: 600,
              letterSpacing: '-0.02em',
              textAlign: isSmallScreen ? 'center' : 'left'
            }}>
              ERP implementations
            </Typography>
            <Typography variant="body1" style={{
              fontFamily: 'Inter',
              fontSize: '2.5vh',
              fontWeight: 300,
              letterSpacing: '-0.02em',
              textAlign: isSmallScreen ? 'center' : 'left'
            }}>
              Using Etendo & OpenBravo software
            </Typography>
            <Typography variant="body1" style={{
              fontFamily: 'Inter',
              fontSize: '2vh',
              fontWeight: 300,
              letterSpacing: '-0.02em',
              textAlign: isSmallScreen ? 'center' : 'left'
            }}>
              integration of various business functions such as finance, operations and HR into a single system.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: '5vh' }} /> {/* Added spacing */}

        <Grid item xs={12} md={3} style={{ textAlign: isSmallScreen ? 'center' : 'center' }}>
          <FontAwesomeIcon icon={faMagnifyingGlassChart} style={{ fontSize: "12vh", color: "#202453" }} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="h3" style={{
            fontFamily: 'Inter',
            fontSize: '3vh',
            fontWeight: 600,
            letterSpacing: '-0.02em',
            textAlign: isSmallScreen ? 'center' : 'left'
          }}>
            BI implementations
          </Typography>
          <Typography variant="body1" style={{
            fontFamily: 'Inter',
            fontSize: '2.5vh',
            fontWeight: 300,
            letterSpacing: '-0.02em',
            textAlign: isSmallScreen ? 'center' : 'left'
          }}>
            With Qlik as the product of choice or using InPhinity as an add-on with Qlik,
          </Typography>
          <Typography variant="body1" style={{
            fontFamily: 'Inter',
            fontSize: '2vh',
            fontWeight: 300,
            letterSpacing: '-0.02em',
            textAlign: isSmallScreen ? 'center' : 'left'
          }}>
            practices of collecting, structuring and analyzing raw data to turn it into actionable insights.
          </Typography>
        </Grid>
      </Grid>


      {/* Mind map */}
      <Grid item xs={12} style={{ paddingTop: isSmallScreen ? "10vh" : '15vh', textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom style={{ fontWeight: 'bold' }}>
          What this means for your business.
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 300, fontSize: 24, width: isSmallScreen ? "100%" : "45%", margin: "auto" }}>
          With the use of ERP & BI implementations we provide the following services and solutions and <span style={{ textDecoration: "underline" }}>more</span>:
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: isSmallScreen ? "100%" : '80vh' }}>
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <img
            src={mindMap}
            alt="Logo"
            style={{
              width: isSmallScreen ? "100%" : "80%",
              height: isSmallScreen ? "100%" : "80%",
              marginBottom: '16px'
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} style={{ display: 'flex', justifyContent: isSmallScreen ? 'center' : 'right', alignItems: isSmallScreen ? 'center' : 'right', paddingRight: isSmallScreen ? "0" : "7vw", paddingBottom: "7vh" }}>
        <Button style={{
          background: '#FBBC05',
          fontFamily: 'Inter',
          fontWeight: 400,
          color: "black",
          borderRadius: "35px",
          padding: "1vh"
        }} onClick={handleClick}>
          <Typography style={{ fontWeight: 300, padding: "1vh" }}>
            Get in touch
          </Typography>
        </Button>
      </Grid>
    </Grid >
  );
}

import axios from "axios";

export class CallsHelper {


  async postItem(path: string, dataToSend: any, token: string): Promise<any> {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    
    return axios.post(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

  }

  async postItemNoAuth(path: string, dataToSend: any): Promise<any> {
    return axios.post(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend)

  }

  async getItem(path: string, token: string): Promise<any> {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    
    return axios.get(`${process.env.REACT_APP_API_BASE}${path}`, config)

  }


  async updateItem(path: string, dataToSend: any, token: string): Promise<any> {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    
    return axios.put(`${process.env.REACT_APP_API_BASE}${path}`, dataToSend, config)

  }


  async deleteItem(path: string, token: string): Promise<any> {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    
    return axios.delete(`${process.env.REACT_APP_API_BASE}${path}`, config)

  }


}
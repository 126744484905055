// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
export function NotFoundPage() {
  return (
    <>
    <Heading level={3}>
      404...
    </Heading>
  </>
  );
}
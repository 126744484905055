import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Card, CardContent, Typography, Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import CurvedRectangle from '../../components/curved_rectangle';
import { useFormControls } from './contactFormControls';

export function ContactUs() {
  const [isLoaded, setIsLoaded] = useState(true);
  const [notificationID, setNotificationID] = useState('');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const inputFieldValues = [
    {
      name: 'first_name',
      label: 'Name',
      id: 'my-name',
      width: '50%',
      padding: '5%',
    },
    {
      name: 'last_name',
      label: 'Last Name',
      id: 'my-lastname',
      width: '50%',
    },
    {
      name: 'email',
      label: 'Email',
      id: 'my-email',
      width: '70%',
    },
    {
      name: 'message',
      label: 'Message',
      id: 'my-message',
      width: '70%',
      height: "10vh",
      multiline: true,
      rows: 4,
    },
  ];

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    values,
  } = useFormControls();

  return (
    <Grid container spacing={0} style={{ width: '100%' }}>
      {/* Heading */}
      <Grid container item xs={12} style={{ paddingBottom: isSmallScreen ? "1vw" : "2vw", }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{ marginLeft: "10vw" }}>
            <div>
              <h2 style={{ fontSize: "5vh", fontWeight: 'bold', color: "#000115" }}>Discover what we can do for your business.</h2>
              <p style={{ fontSize: "2.3vh", fontWeight: 400, color: "#828282", paddingTop: "2vh" }}>Our main objective is to offer you solutions and equip you with the right tools to manage your business efficiently.</p>
            </div>
          </Grid>
          <Grid item xs={12} md={12} style={{
            height: "10vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}>
            <CurvedRectangle />
          </Grid>
        </Grid>
      </Grid>

      {/* Card */}
      <Grid container item xs={12} style={{ paddingBottom: "2vw", height: "100%", paddingTop: isSmallScreen ? "4vh": "0" }}>
        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%" }}>
          <Card style={{ backgroundColor: '#E0F483', borderRadius: '40px', padding: '16px', width: '60%', height: "30vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardContent style={{ textAlign: 'center' }}>
              <Typography variant="h5" component="div" style={{ color: '#202453' }}>
                <h3 style={{ margin: 0 }}>Leave us a message and we will contact you</h3>
                {/* <p style={{ fontWeight: 300, fontSize: 22, margin: 0, paddingTop: "2vh" }}>We will get back to you as soon as possible.</p> */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Form */}
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: isSmallScreen ? "center": undefined,
            paddingBottom: '2vh',
            height: '100%',
            paddingTop: isSmallScreen ? "4vh": "0"
          }}
        >
          <div style={{ width: isSmallScreen ? '75%' : '100%', maxWidth: isSmallScreen ? '600px' : 'none', margin: isSmallScreen ? '0' : '0' }}>
            <form autoComplete="off" onSubmit={handleFormSubmit}>
              {inputFieldValues.map((inputFieldValue, index) => {
                return (
                  <div key={index} style={{ marginBottom: '1px' }}>
                    <label htmlFor={inputFieldValue.name} style={{ fontWeight: 500, fontSize: 20, color: "#000115" }}>{inputFieldValue.label}</label>
                    <br />
                    <TextField
                      id={inputFieldValue.name}
                      onChange={handleInputValue}
                      onBlur={handleInputValue}
                      name={inputFieldValue.name}
                      error={!!errors[inputFieldValue.name]}
                      style={{ width: isSmallScreen ? '90%' : inputFieldValue.width, paddingBottom: '2%' }}
                      value={values[inputFieldValue.name] || ''}
                      rows={inputFieldValue.rows ?? 1}
                      autoComplete="none"
                      multiline={(inputFieldValue.rows ?? 1) > 1}
                      {...(errors[inputFieldValue.name] && {
                        error: true,
                        helperText: errors[inputFieldValue.name],
                      })}
                      InputProps={{
                        style: {
                          backgroundColor: 'white',
                          border: 'solid #E0E0E0 1px',
                          borderRadius: '10px',
                          height: inputFieldValue.height ? `${inputFieldValue.height}` : '6vh',
                        },
                      }}
                      variant="outlined"
                    />
                  </div>
                );
              })}
              <div style={{ display: 'flex', marginTop: '16px', justifyContent: isSmallScreen? 'center': 'left' }}>
                <Button type="submit" disabled={!formIsValid()} style={{ color: "#FFFFFF", borderRadius: '10px', backgroundColor: "#202453", width: "70%", height: "5vh" }}>
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </Grid>


      </Grid>
    </Grid>
  );
}
